import React, { useRef } from 'react';
import { PdfRegular, PdfSubHeader } from '@components';

interface PdfPageBrandingSubHeaderProps {
  data: any;
}

export const PdfPageBrandingSubHeader = ({
  data,
}: PdfPageBrandingSubHeaderProps) => {
  const mainBoxCSS = useRef<any>({});
  const titleCSS = useRef<any>({});
  const subTitleCSS = useRef<any>({});
  if (data.pageBranding?.subHeader?.mainBoxCSS) {
    try {
      mainBoxCSS.current = eval(
        '({' + data.pageBranding?.subHeader?.mainBoxCSS + '})',
      );
    } catch (e) {
      mainBoxCSS.current = {};
    }
  }
  if (data.pageBranding?.subHeader?.titleCSS) {
    try {
      titleCSS.current = eval(
        '({' + data.pageBranding?.subHeader?.titleCSS + '})',
      );
    } catch (e) {
      titleCSS.current = {};
    }
  }
  if (data.pageBranding?.subHeader?.subTitleCSS) {
    try {
      subTitleCSS.current = eval(
        '({' + data.pageBranding?.subHeader?.subTitleCSS + '})',
      );
    } catch (e) {
      subTitleCSS.current = {};
    }
  }

  return (
    <PdfSubHeader style={{ ...mainBoxCSS.current }} fixed>
      <PdfRegular style={{ ...titleCSS.current }}>
        {data.pageBranding?.subHeader?.title}
      </PdfRegular>
      <PdfRegular style={{ ...subTitleCSS.current }}>{`${
        data.pageBranding?.subHeader?.subTitle || ''
      }`}</PdfRegular>
    </PdfSubHeader>
  );
};
