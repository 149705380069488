import React, { useEffect, useState } from 'react';
import { useStores } from '@/stores';
import dayjs from 'dayjs';
import { Toast } from '@/library/components';

interface PatientTraceabilityModalProps {
  type?: string;
  isOpen: boolean;
  onClose: () => void;
  pId?: number;
  documents?: string;
  labId?: number;
}

const PatientTraceabilityModal: React.FC<PatientTraceabilityModalProps> = ({
  type,
  isOpen,
  onClose,
  pId,
  documents,
  labId,
}) => {
  const { eventLogsStore } = useStores();
  const [hasData, setHasData] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);

  const checkDataAndOpenModal = () => {
    eventLogsStore.eventLogsService.listEventLogs().then(() => {
      const filteredDataByType = eventLogsStore.eventLogsList?.filter(log => {
        const matchesPId = pId ? log.pId === pId : true;
        const matchesDocuments = documents
          ? log?.documents?.includes(documents)
          : true;

        return matchesPId && matchesDocuments;
      });

      if (filteredDataByType?.length) {
        setHasData(true);
        setModalVisible(true);
      } else {
        setHasData(false);
        Toast.warning({
          message: 'No traceability found for the selected data.',
        });
      }
    });
  };

  useEffect(() => {
    if (isOpen) {
      checkDataAndOpenModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  if (!modalVisible) return null;

  const headersByType = {
    'Patient Manager': [
      { key: 'pId', label: 'PID' },
      { key: 'event', label: 'Event' },
      { key: 'eventOn', label: 'Event On' },
      { key: 'oldValue', label: 'Old Value' },
      { key: 'newValue', label: 'New Value' },
      { key: 'eventDate', label: 'Event Date' },
      { key: 'eventBy', label: 'Event By' },
      { key: 'comments', label: 'Comments' },
    ],
    'Patient Visit': [
      { key: 'labId', label: 'Lab ID' },
      { key: 'rLab', label: 'RLab' },
      { key: 'event', label: 'Event' },
      { key: 'eventOn', label: 'Event On' },
      { key: 'oldValue', label: 'Old Value' },
      { key: 'newValue', label: 'New Value' },
      { key: 'eventDate', label: 'Event Date' },
      { key: 'eventBy', label: 'Event By' },
      { key: 'comments', label: 'Comments' },
    ],
    'Patient Order': [
      { key: 'labId', label: 'Lab ID' },
      { key: 'department', label: 'Department' },
      { key: 'panelCode', label: 'Panel Code' },
      { key: 'event', label: 'Event' },
      { key: 'eventOn', label: 'Event On' },
      { key: 'oldValue', label: 'Old Value' },
      { key: 'newValue', label: 'New Value' },
      { key: 'eventDate', label: 'Event Date' },
      { key: 'eventBy', label: 'Event By' },
      { key: 'comments', label: 'Comments' },
    ],
    'Patient Test': [
      { key: 'labId', label: 'Lab ID' },
      { key: 'panelCode', label: 'Panel Code' },
      { key: 'department', label: 'Department' },
      { key: 'testCode', label: 'Test Code' },
      { key: 'event', label: 'Event' },
      { key: 'eventOn', label: 'Event On' },
      { key: 'oldValue', label: 'Old Value' },
      { key: 'newValue', label: 'New Value' },
      { key: 'eventDate', label: 'Event Date' },
      { key: 'eventBy', label: 'Event By' },
      { key: 'comments', label: 'Comments' },
    ],
    'Patient Result': [
      { key: 'labId', label: 'Lab ID' },
      { key: 'panelCode', label: 'Panel Code' },
      { key: 'department', label: 'Department' },
      { key: 'testCode', label: 'Test Code' },
      { key: 'analyteCode', label: 'Analyte Code' },
      { key: 'event', label: 'Event' },
      { key: 'eventOn', label: 'Event On' },
      { key: 'oldValue', label: 'Old Value' },
      { key: 'newValue', label: 'New Value' },
      { key: 'eventDate', label: 'Event Date' },
      { key: 'eventBy', label: 'Event By' },
      { key: 'comments', label: 'Comments' },
    ],
    'Patient Sample': [
      { key: 'labId', label: 'Lab ID' },
      { key: 'sampleId', label: 'Sample ID' },
      { key: 'department', label: 'Department' },
      { key: 'event', label: 'Event' },
      { key: 'eventOn', label: 'Event On' },
      { key: 'oldValue', label: 'Old Value' },
      { key: 'newValue', label: 'New Value' },
      { key: 'eventDate', label: 'Event Date' },
      { key: 'eventBy', label: 'Event By' },
      { key: 'comments', label: 'Comments' },
    ],
  };

  const filteredDataByType = eventLogsStore.eventLogsList?.filter(log => {
    const matchesPId = pId ? log.pId === pId : true; // Optional check for pId
    const matchesDocuments = documents
      ? log?.documents?.includes(documents)
      : true;

    return matchesPId && matchesDocuments;
  });

  return (
    <div>
      {/* Modal Background Overlay */}
      <div
        className='fixed inset-0 bg-[#00000024] transition-opacity'
        style={{ zIndex: 9998 }}
      ></div>
      {/* Modal Container */}
      <div className='fixed inset-0 z-[9999] overflow-y-auto'>
        <div className='flex items-center justify-center w-full min-h-screen p-4'>
          {/* Modal Box */}
          <div className='bg-white rounded-lg shadow-xl w-full max-w-7xl p-4 relative'>
            {/* Modal Header */}
            <div className='flex justify-between items-center pb-2 border-b border-gray-200'>
              <h2 className='text-xl font-semibold text-gray-800'>
                {type || 'Patient Traceability'}
              </h2>
              <button
                className='text-gray-400 hover:text-gray-600'
                onClick={() => {
                  setModalVisible(false);
                  onClose();
                }}
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  className='h-5 w-5'
                  fill='none'
                  viewBox='0 0 24 24'
                  stroke='currentColor'
                  strokeWidth='2'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    d='M6 18L18 6M6 6l12 12'
                  />
                </svg>
              </button>
            </div>

            {/* Modal Body */}
            <div className='mt-4 overflow-auto'>
              {type && headersByType[type] ? (
                <div className='mb-6'>
                  <h3 className='text-lg font-semibold text-gray-700 mb-2'>
                    {type}
                  </h3>
                  <table className='table-auto border-collapse w-full text-left'>
                    <thead>
                      <tr>
                        {headersByType[type].map(header => (
                          <th
                            key={header.key}
                            className='border-b bg-[#6A727F] min-w-[150px] text-white px-4 py-2 font-semibold text-sm sticky top-0 z-10'
                          >
                            {header.label}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {filteredDataByType && filteredDataByType.length > 0 ? (
                        filteredDataByType.map((row, rowIndex) => (
                          <tr key={rowIndex} className='hover:bg-gray-100'>
                            {headersByType[type].map(header => {
                              const cellValue = row[header.key] || '';
                              const isEventOnDate =
                                row.eventOn && row.eventOn.includes('Date');

                              let displayValue = cellValue;

                              if (
                                header.key === 'eventDate' &&
                                dayjs(displayValue).isValid()
                              ) {
                                displayValue = dayjs(displayValue).format(
                                  'DD-MM-YYYY hh:mm:ss',
                                );
                              }

                              if (
                                isEventOnDate &&
                                (header.key === 'oldValue' ||
                                  header.key === 'newValue') &&
                                dayjs(displayValue).isValid()
                              ) {
                                displayValue = dayjs(displayValue).format(
                                  'DD-MM-YYYY hh:mm:ss',
                                );
                              }

                              return (
                                <td
                                  key={`${rowIndex}-${header.key}`}
                                  className='border-b px-4 py-2 text-sm text-gray-700 min-w-[200px]'
                                >
                                  {displayValue}
                                </td>
                              );
                            })}
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td
                            colSpan={headersByType[type].length}
                            className='text-center py-4 text-gray-500'
                          >
                            No data available
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              ) : (
                <div className='text-center text-gray-500'>
                  No data available for the selected type
                </div>
              )}
            </div>

            {/* Modal Footer */}
            <div className='mt-4 flex justify-end'>
              <button
                className='px-3 py-1 bg-gray-500 text-white text-sm rounded-md hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500'
                onClick={() => {
                  setModalVisible(false);
                  onClose();
                }}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PatientTraceabilityModal;
