export class PageBranding {
  _id: string;
  layoutCode: string;
  brandingCode: string;
  brandingTitle: string;
  templateSettings: any;
  header: {
    title: string;
    titleCSS: any;
    logo: any;
    logoCSS: number;
    backgroundImage: any;
    backgroundImageBase64: string;
    mainBoxCSS: string;
  };
  subHeader: {
    title: string;
    titleCSS: string;
    subTitle: string;
    subTitleCSS: string;
    mainBoxCSS: string;
  };
  fields: {
    grid: number;
    fieldsList: Array<any>;
    fieldsCSS: string;
    mainBoxCSS: string;
  };
  footer: {
    title: string;
    titleCSS: string;
    subTitle: string;
    subTitleCSS: string;
    backgroundImage: any;
    backgroundImageBase64: string;
    mainBoxCSS: string;
  };
  pageNumber: {
    pageNumberCSS: string;
  };
  isHeader: boolean;
  isSubHeader: boolean;
  isFields: boolean;
  isFooter: boolean;
  isPdfPageNumber: boolean;
  companyCode: string; // Added companyCode
  environment: string; // Added environment
  status: string;
  documentType: string;
  dateOfEntry: Date;
  lastUpdated: Date;

  static types = {
    _id: 'string',
    layoutCode: 'string',
    brandingCode: 'string',
    brandingTitle: 'string',
    templateSettings: 'any',
    header: 'object',
    subHeader: 'object',
    fields: 'object',
    footer: 'object',
    pageNumber: 'object',
    isHeader: 'boolean',
    isSubHeader: 'boolean',
    isFields: 'boolean',
    isFooter: 'boolean',
    isPdfPageNumber: 'boolean',
    companyCode: 'string',
    environment: 'string',
    status: 'string',
    documentType: 'string',
    dateOfEntry: 'Date',
    lastUpdated: 'Date',
  };

  constructor(rawData: { [key in string]: any }) {
    this._id = rawData._id;
    this.layoutCode = rawData.layoutCode;
    this.brandingCode = rawData.brandingCode;
    this.brandingTitle = rawData.brandingTitle;
    this.templateSettings = rawData.templateSettings;
    this.header = rawData.header;
    this.subHeader = rawData.subHeader;
    this.fields = rawData.fields;
    this.footer = rawData.footer;
    this.pageNumber = rawData.pageNumber;
    this.isHeader = rawData.isHeader;
    this.isSubHeader = rawData.isSubHeader;
    this.isFields = rawData.isFields;
    this.isFooter = rawData.isFooter;
    this.isPdfPageNumber = rawData.isPdfPageNumber;
    this.companyCode = rawData.companyCode;
    this.environment = rawData.environment;
    this.status = rawData.status;
    this.documentType = rawData.documentType;
    this.dateOfEntry = rawData.dateOfEntry;
    this.lastUpdated = rawData.lastUpdated;
  }
}
