import { Icons, Tooltip } from '@/library/components';
import React from 'react';

interface ScreenChildren {
  name?: string;
}

interface Screen {
  children?: ScreenChildren;
}

interface FieldItem {
  screen?: Screen;
  field?: string;
  fieldValue?: string;
  label?: string;
  id?: string;
}

interface RowData {
  _id: string;
  screen?: unknown;
  fields?: FieldItem[];
}

interface ModalFieldsUpdate {
  visible: boolean;
  _id?: string;
  fields?: FieldItem[];
  screen?: unknown;
}

interface FieldsTableProps {
  row: RowData;
  selectedRowId: string;
  setSelectedRowId: React.Dispatch<React.SetStateAction<string>>;
  setWidthRefBox: React.Dispatch<React.SetStateAction<string>>;
  setModalFieldsUpdate: React.Dispatch<React.SetStateAction<ModalFieldsUpdate>>;
}

const FieldsTable: React.FC<FieldsTableProps> = ({
  row,
  selectedRowId,
  setSelectedRowId,
  setWidthRefBox,
  setModalFieldsUpdate,
}) => {
  return (
    <>
      <div>
        <Tooltip
          tooltipText={
            row._id !== selectedRowId ? 'Expand Fields' : 'Collapse Fields'
          }
        >
          <Icons.IconContext
            color='#000000'
            size='20'
            onClick={() => {
              if (row._id === selectedRowId) {
                setSelectedRowId('');
                setWidthRefBox('30px');
              } else {
                setSelectedRowId(row._id);
                setWidthRefBox('800px');
              }
            }}
          >
            {Icons.getIconTag(
              row._id !== selectedRowId
                ? Icons.IconBi.BiExpand
                : Icons.IconBi.BiCollapse,
            )}
          </Icons.IconContext>
        </Tooltip>
      </div>

      {selectedRowId === row?._id && (
        <div
          className='flex flex-row gap-2 min-w-[500px]'
          onDoubleClick={() => {
            setModalFieldsUpdate({
              visible: true,
              _id: row?._id,
              fields: row?.fields,
              screen: row?.screen,
            });
          }}
        >
          {row?.fields && row?.fields?.length > 0 ? (
            <div className='max-h-64 overflow-auto border border-gray-300'>
              <table className='w-full border-collapse'>
                <thead className='sticky top-0 bg-gray-200'>
                  <tr>
                    <th className='border border-gray-300 p-2 whitespace-nowrap'>
                      Screen
                    </th>
                    <th className='border border-gray-300 p-2 whitespace-nowrap'>
                      Field
                    </th>
                    <th className='border border-gray-300 p-2 whitespace-nowrap'>
                      Field Value
                    </th>
                    <th className='border border-gray-300 p-2 whitespace-nowrap'>
                      Label
                    </th>
                    <th className='border border-gray-300 p-2 whitespace-nowrap'>
                      Id
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {row?.fields?.map((item, index) => (
                    <tr key={index}>
                      <td className='border border-gray-300 p-2 whitespace-nowrap overflow-hidden text-ellipsis'>
                        {item?.screen?.children?.name || ''}
                      </td>
                      <td className='border border-gray-300 p-2 whitespace-nowrap overflow-hidden text-ellipsis'>
                        {item?.field || ''}
                      </td>
                      <td className='border border-gray-300 p-2 whitespace-nowrap overflow-hidden text-ellipsis'>
                        {item?.fieldValue || ''}
                      </td>
                      <td className='border border-gray-300 p-2 whitespace-nowrap overflow-hidden text-ellipsis'>
                        {item?.label || ''}
                      </td>
                      <td className='border border-gray-300 p-2 whitespace-nowrap overflow-hidden text-ellipsis'>
                        {item?.id || ''}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <div className='flex bg-blue-700 p-2 rounded-sm'>
              <Tooltip tooltipText='Update'>
                <Icons.RIcon
                  nameIcon='FaPencilAlt'
                  propsIcon={{ size: 20, color: '#ffffff' }}
                  onClick={() =>
                    setModalFieldsUpdate({
                      visible: true,
                      _id: row?._id,
                      fields: row?.fields,
                      screen: row?.screen,
                    })
                  }
                />
              </Tooltip>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default React.memo(FieldsTable);
