import { gql } from '@apollo/client';

export const CREATE = gql`
  mutation ($input: CreateInstrumentMasterInput!) {
    createInstrumentMaster(input: $input) {
      success
      message
    }
  }
`;
