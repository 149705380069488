import React, { useRef } from 'react';
import { PdfView, PdfFooterView, PdfPageNumber } from '@components';
import { observer } from 'mobx-react';
import { PdfPageLayout } from '../page-layout/pageLayout.component';
import { PdfPageBrandingHeader } from './pageBranding-header.component';
import { PdfPageBrandingSubHeader } from './pageBranding-sub-header.component';
import { PdfPageBrandingFields } from './pageBranding-fields.component';
import { PdfPageBrandingFooter } from './pageBranding-footer.component';

interface PdfPageBrandingProps {
  data: any;
  children?: any;
}

export const PdfPageBranding = observer(
  ({ data, children }: PdfPageBrandingProps) => {
    const { pageLayout, pageBranding, reportBody, templatePatientResult } =
      data;
    const pageNumberCSS = useRef<any>({});
    if (data?.pageNumber?.pageNumberCSS) {
      try {
        pageNumberCSS.current = eval(
          '({' + data?.pageNumber?.pageNumberCSS + '})',
        );
      } catch (e) {
        pageNumberCSS.current = {};
      }
    }

    return (
      <PdfPageLayout
        height={window.innerHeight / 1.3}
        documentTitle='Page Branding'
        isToolbar={pageLayout?.isToolbar}
        isBackgroundImage={pageLayout?.isBackgroundImage || ''}
        backgroundImage={pageLayout?.backgroundImage || ''}
        mainBoxCSS={pageLayout?.mainBoxCSS}
        pageSize={pageLayout?.pageSize}
        children={
          <>
            <PdfView style={{ height: 100 }} fixed mh={0} p={0}>
              <>
                {/* Header */}
                {pageBranding?.isHeader && (
                  <PdfPageBrandingHeader data={data} />
                )}

                {/* Sub Header */}
                {pageBranding?.isSubHeader && (
                  <PdfPageBrandingSubHeader data={data} />
                )}
                {/* Fields */}
                {pageBranding?.isFields && (
                  <PdfPageBrandingFields data={data} />
                )}
              </>
            </PdfView>

            {/* children */}
            {children}

            {/* Page Number */}
            {data?.isPdfPageNumber && (
              <PdfPageNumber style={{ ...pageNumberCSS.current }} />
            )}

            <PdfFooterView fixed bg='transparent' style={{ height: 40 }} p={0}>
              {pageBranding?.isFooter && <PdfPageBrandingFooter data={data} />}
            </PdfFooterView>
          </>
        }
      />
    );
  },
);
