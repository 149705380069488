import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useStores } from '@/stores';
import { getDefaultLookupItem } from '@/library/utils';

export const InstResultMappingHoc = (WrappedComponent) => {
  return observer((props: any): JSX.Element => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { loginStore, instResultMappingStore, routerStore } = useStores();
    const initialLoad = () => {
      instResultMappingStore.updateInstResultMapping([
        {
          index: 1,
          environment: getDefaultLookupItem(
            routerStore.lookupItems,
            'ENVIRONMENT',
          ),
          status: getDefaultLookupItem(routerStore.lookupItems, 'STATUS'),
          enteredBy: loginStore.login?.userId,
        },
      ]);
    };

    return <WrappedComponent {...props} initialLoad={initialLoad} />;
  });
};
