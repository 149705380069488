import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';

import { Confirm } from '@/library/models';
import dayjs from 'dayjs';

import { TableBootstrap } from './table-bootstrap.components';

import {
  Form,
  Tooltip,
  Icons,
  NumberFilter,
  sortCaret,
  customFilter,
} from '@/library/components';

import { DisplayResult } from '../../../../../result-entry/general-result-entry/components/molecules/output/display-result.components';

import {
  getResultStatus,
  getTestStatus,
  getAbnFlag,
  getCretical,
} from '../../../../../result-entry/general-result-entry/utils';
import { RefRanges } from './ref-ranges.component';
import _ from 'lodash';

interface ResultProps {
  data: any;
  totalSize: number;
  isDelete?: boolean;
  isEditModify?: boolean;
  onUpdate?: (selectedItem: Confirm) => void;
  onSelectedRow?: (selectedItem: any) => void;
  onUpdateItem?: (value: any, dataField: string, id: string) => void;
  onPageSizeChange?: (page: number, totalSize: number) => void;
  onUpdateResult?: (fields: any, id: string, patientResultId: string) => void;
  onFilter?: (
    type: string,
    filter: any,
    page: number,
    totalSize: number,
  ) => void;
  onClickRow?: (item: any, index: number) => void;
  onReport?: (item: any) => void;
  onUpdateFields?: (fields: any, id: string) => void;
  onUpdateRecordsByResultList?: (list: any) => void;
}

export const Result = observer((props: ResultProps) => {
  const [selectedItem, setSelectedItem] = useState<any>({});
  const [widthRefBox, setWidthRefBox] = useState('20px');
  const [selectedRowId, setSelectedRowId] = useState('');
  const [widthConculsionBox, setWidthConculsionBox] = useState('20px');
  const [conclusionId, setWidthConculsionId] = useState('');

  const [localData, setLocalData] = useState([]);

  useEffect(() => {
    setLocalData(
      props.data?.sort((a, b) =>
        (a?.resultOrder || '') > (b?.resultOrder || '') ? 1 : -1,
      ),
    );
  }, [props?.data]);
  const renderRangeValue = record => {
    const { loNor, hiNor } = record;
    return _.isNaN(Number.parseFloat(loNor)) &&
      _.isNaN(Number.parseFloat(hiNor))
      ? ' '
      : _.isNaN(Number.parseFloat(loNor))
      ? `< ${hiNor}`
      : _.isNaN(Number.parseFloat(hiNor))
      ? `${loNor}  >`
      : `${loNor} - ${hiNor}`;
  };

  return (
    <>
      <div>
        <TableBootstrap
          id='_id'
          data={localData}
          totalSize={props?.totalSize}
          selectedItem={selectedItem}
          columns={[
            {
              dataField: '_id',
              text: 'Id',
              hidden: true,
              csvExport: false,
            },
            {
              dataField: 'sampleId',
              text: 'Sample Id',
              sort: true,
              editable: false,
              headerClasses: 'textHeaderm',
            },
            {
              dataField: 'test',
              text: 'Test',
              sort: true,
              editable: false,
              headerClasses: 'textHeader6',
              style: {
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                minWidth: 0,
                maxWidth: '130px',
                position: 'relative',
              },
              formatter: (cellContent, row) => {
                return <span title={row.test}>{cellContent}</span>;
              },
            },
            {
              dataField: 'analyte',
              text: 'Analyte',
              sort: true,
              editable: false,
              headerClasses: 'textHeader2',
              style: {
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                minWidth: 0,
                maxWidth: '130px',
                position: 'relative',
              },
              formatter: (cellContent, row) => {
                return <span title={row.analyte}>{cellContent}</span>;
              },
            },
            {
              dataField: 'reportable',
              text: 'Reportable',
              sort: true,
              editable: false,
              formatter: (cell, row) => {
                return (
                  <>
                    <Form.Toggle
                      value={row?.reportable}
                      onChange={reportable => {
                        props?.onUpdateRecordsByResultList &&
                          props?.onUpdateRecordsByResultList([
                            { ...row, reportable },
                          ]);
                      }}
                    />
                  </>
                );
              },
            },
            {
              dataField: 'result',
              text: 'Result',
              sort: true,
              headerClasses: 'textHeader',
              // editable: false,
              editable: (content, row, rowIndex, columnIndex) => {
                if (row?.resultType === 'W') return false;
                else row?.isResultUpdate;
              },
              formatter: (cellContent, row) => (
                <>
                  {/* {row?.resultType === 'W' || row.resultType === 'F' ? ( */}
                  <>
                    <DisplayResult
                      type='PANEL'
                      row={{ ...row, panelStatus: 'P' }}
                      rowIndex={Math.random()}
                      rowData={row}
                      onSelect={result => {
                        if (row?.result != result?.result) {
                          const rows = {
                            ...row,
                            panelStatus: row?.panelStatus,
                            ...result,
                          };
                          if (
                            row?.resultType == 'V' &&
                            props?.data?.some(item => item?.calculationFlag)
                          ) {
                            let resultList = props?.data?.map(item => {
                              if (item?._id == row?._id)
                                return { ...item, result: result?.result };
                              else return item;
                            });

                            // calculation
                            const resultsCalFlagFalse = resultList
                              ?.map(item => {
                                if (item?.calculationFlag)
                                  return {
                                    _id: item?._id,
                                    calculationFormula:
                                      item?.calculationFormula,
                                  };
                              })
                              ?.filter(item => item);
                            resultsCalFlagFalse?.map((calItem, index) => {
                              let splitFormula: any =
                                calItem?.calculationFormula.split('=').pop();
                              splitFormula = splitFormula?.split('');
                              let fullFormula = splitFormula?.join('');
                              // console.log({ splitFormula, beforeFormula: fullFormula });
                              const regExp = /[a-zA-Z]/g;
                              const regExp1 = /[^a-zA-Z]+/g;
                              splitFormula.map(fl => {
                                if (regExp.test(fl)) {
                                  fullFormula = fullFormula?.replace(
                                    fl?.replace(regExp1, ''),
                                    resultList?.find(
                                      val =>
                                        val?.calculationVariable ===
                                        fl?.replace(regExp1, ''),
                                    )?.result || '0',
                                  );
                                }
                              });
                              resultList = resultList?.map(item => {
                                if (item?._id == calItem?._id) {
                                  return {
                                    ...item,
                                    result: Number.parseFloat(
                                      eval(fullFormula).toString(),
                                    ).toFixed(row?.picture || 0),
                                    numeric: eval(fullFormula).toString(),
                                    testStatus: 'N',
                                    resultStatus: 'N',
                                  };
                                } else if (item?._id == row?._id) {
                                  return {
                                    ...item,
                                    resultStatus: getResultStatus(
                                      rows.resultType,
                                      rows,
                                    ),
                                    testStatus: getTestStatus(
                                      rows.resultType,
                                      rows,
                                    ),
                                    abnFlag: getAbnFlag(rows.resultType, rows),
                                    critical: getCretical(
                                      rows.resultType,
                                      rows,
                                    ),
                                    result: result?.result,
                                  };
                                }
                              });
                              if (index == 0) {
                                props?.onUpdateRecordsByResultList &&
                                  props?.onUpdateRecordsByResultList(
                                    resultList?.filter(item => item),
                                  );
                              }
                            });
                          } else {
                            props.onUpdateResult &&
                              props.onUpdateResult(
                                {
                                  ...rows,
                                  resultStatus: getResultStatus(
                                    rows.resultType,
                                    rows,
                                  ),
                                  testStatus: getTestStatus(
                                    rows.resultType,
                                    rows,
                                  ),
                                  abnFlag: getAbnFlag(rows.resultType, rows),
                                  critical: getCretical(rows.resultType, rows),
                                  result: result?.result,
                                },
                                rows._id,
                                rows.patientResultId,
                              );
                          }
                        }
                      }}
                    />
                  </>
                  {/* ) : (
                    <span>{row?.result}</span>
                  )} */}
                </>
              ),
            },
            {
              dataField: 'showRanges',
              text: 'Show Range',
              sort: true,
              editable: false,
              formatter: (cell, row) => {
                return (
                  <>
                    <Form.Toggle
                      value={row?.showRanges}
                      onChange={showRanges => {
                        props?.onUpdateRecordsByResultList &&
                          props?.onUpdateRecordsByResultList([
                            { ...row, showRanges },
                          ]);
                      }}
                    />
                  </>
                );
              },
            },
            {
              dataField: 'abnFlag',
              text: 'Abn Flag',
              sort: true,
              editable: false,
              formatter: (cell, row) => {
                return (
                  <>
                    <Form.Toggle disabled={true} value={row.abnFlag} />
                  </>
                );
              },
            },
            {
              dataField: 'critical',
              text: 'Critical',
              sort: true,
              editable: false,
              formatter: (cell, row) => {
                return (
                  <>
                    <Form.Toggle disabled={true} value={row.critical} />
                  </>
                );
              },
            },

            {
              dataField: 'calculationFlag',
              text: 'Calculation Flag',
              sort: true,
              editable: false,
              formatter: (cell, row) => {
                return (
                  <>
                    <Form.Toggle disabled={true} value={row?.calculationFlag} />
                  </>
                );
              },
            },

            {
              dataField: 'refRangesList',
              text: 'Ref Ranges',
              sort: true,
              editable: false,
              headerClasses: 'textHeaderxxm',
              style: { width: widthRefBox },
              formatter: (cell, row) => {
                return (
                  <>
                    <div className='flex flex-row items-center justify-between space-x-2'>
                      <span className='flex-1 text-center'>
                        {renderRangeValue(row)}
                      </span>
                      <div className='flex items-center justify-center'>
                        {row.refRangesList?.length > 0 && (
                          <Tooltip
                            tooltipText={
                              row._id !== selectedRowId ? 'Expand' : 'Collapse'
                            }
                          >
                            <Icons.IconContext
                              color='#000000'
                              size='20'
                              onClick={() => {
                                if (row._id === selectedRowId) {
                                  setSelectedRowId('');
                                  setWidthRefBox('30px');
                                } else {
                                  setSelectedRowId(row._id);
                                  setWidthRefBox('550px');
                                }
                              }}
                            >
                              {Icons.getIconTag(
                                row._id !== selectedRowId
                                  ? Icons.IconBi.BiExpand
                                  : Icons.IconBi.BiCollapse,
                              )}
                            </Icons.IconContext>
                          </Tooltip>
                        )}
                      </div>
                    </div>

                    {selectedRowId == row._id ? (
                      <div style={{ width: widthRefBox }}>
                        <RefRanges
                          id='_id'
                          data={row?.refRangesList || []}
                          totalSize={row?.refRangesList?.length || 0}
                          columns={[
                            {
                              dataField: 'result',
                              text: 'Result',
                              editable: false,
                              formatter: () => (
                                <>
                                  <span>{row.result}</span>
                                </>
                              ),
                            },
                            {
                              dataField: 'rangeType',
                              text: 'Range Type',
                            },
                            {
                              dataField: 'low',
                              text: 'Low',
                            },
                            {
                              dataField: 'high',
                              text: 'High',
                            },
                            {
                              dataField: 'rangeSetOn',
                              text: 'Range Set On',
                            },
                            {
                              dataField: 'rangeId',
                              text: 'Range Id',
                            },
                            {
                              dataField: 'version',
                              text: 'Range Version',
                            },
                          ]}
                          onSelectedRow={rows => {}}
                          onUpdateItem={(
                            value: any,
                            dataField: string,
                            id: string,
                          ) => {}}
                        />
                      </div>
                    ) : null}
                  </>
                );
              },
            },
            {
              dataField: 'units',
              text: 'Units',
              sort: true,
              editable: false,
            },
            {
              dataField: 'conclusion',
              text: 'Conclusion',
              style: { width: widthConculsionBox },
              editable: (content, row, rowIndex, columnIndex) =>
                row?.isResultUpdate,
              formatter: (cell, row) => {
                return (
                  <>
                    <div className='flex justify-between flex-row gap-2'>
                      <span>{row?.conclusion?.toString()}</span>
                      <div className='flex flex-col'>
                        <Tooltip
                          tooltipText={
                            row._id != conclusionId ? 'Expand' : 'Collapse'
                          }
                        >
                          <Icons.IconContext
                            color='#000000'
                            size='20'
                            onClick={() => {
                              if (row._id === conclusionId) {
                                setWidthConculsionId('');
                                setWidthConculsionBox('30px');
                              } else {
                                setWidthConculsionId(row._id);
                                setWidthConculsionBox('200px');
                              }
                            }}
                          >
                            {Icons.getIconTag(
                              row._id != conclusionId
                                ? Icons.IconBi.BiExpand
                                : Icons.IconBi.BiCollapse,
                            )}
                          </Icons.IconContext>
                        </Tooltip>
                        {row._id === conclusionId && (
                          <div style={{ width: widthConculsionBox }}>
                            <Form.MultilineInput
                              rows={3}
                              placeholder='Conclusion'
                              className='text-black'
                              onBlur={conclusion => {
                                props.onUpdateFields &&
                                  props.onUpdateFields({ conclusion }, row._id);
                                setWidthConculsionId('');
                                setWidthConculsionBox('30px');
                              }}
                              defaultValue={row?.conclusion}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </>
                );
              },
            },
            {
              dataField: 'sampleType',
              text: 'Sample Type',
              sort: true,
              editable: false,
              headerClasses: 'textHeaderm',
              style: {
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                minWidth: 0,
                maxWidth: '130px',
                position: 'relative',
              },
              formatter: (cellContent, row) => {
                return <span title={row.sampleType}>{cellContent}</span>;
              },
            },
            {
              dataField: 'containerId',
              text: 'Container Id',
              sort: true,
              editable: false,
            },
            {
              dataField: 'remarks',
              text: 'Remarks',
              sort: true,
              editable: false,
            },
            {
              dataField: 'deltaFlag',
              text: 'Delta Flag',
              sort: true,
              editable: false,
              formatter: (cell, row) => {
                return (
                  <>
                    <Form.Toggle disabled={true} value={row.deltaFlag} />
                  </>
                );
              },
            },
            {
              dataField: 'deltaValue',
              text: 'Delta Value',
              sort: true,
              editable: false,
            },
            {
              dataField: 'Result Status',
              text: 'Result Status',
              sort: true,
              editable: false,
            },
            {
              dataField: 'testStatus',
              text: 'Test Status',
              sort: true,
              editable: false,
            },
            {
              dataField: 'approvalDate',
              text: 'Approval Date',
              sort: true,
              editable: false,
              headerClasses: 'textHeaderm',
              formatter: (cell, row) => {
                return row.approvalDate
                  ? dayjs(row.approvalDate).format('DD-MM-YYYY HH:mm:ss')
                  : '';
              },
            },
            {
              dataField: 'autoRelease',
              text: 'Auto Release',
              sort: true,
              editable: false,
              formatter: (cell, row) => {
                return (
                  <>
                    <Form.Toggle disabled={true} value={row.autoRelease} />
                  </>
                );
              },
            },
            {
              dataField: 'final',
              text: 'Final',
              sort: true,
              editable: false,
            },
            {
              dataField: 'enteredBy',
              text: 'Entered By',
              sort: true,

              editable: false,
            },
          ]}
          isEditModify={true}
          isSelectRow={true}
          fileName='Pending Panel Approval'
          onSelectedRow={rows => {
            props.onSelectedRow &&
              props.onSelectedRow(rows.map((item: any) => item._id));
          }}
          onUpdateItem={(value: any, dataField: string, id: string) => {
            props.onUpdateItem && props.onUpdateItem(value, dataField, id);
          }}
          onPageSizeChange={(page, size) => {
            props.onPageSizeChange && props.onPageSizeChange(page, size);
          }}
          onFilter={(type, filter, page, size) => {
            props.onFilter && props.onFilter(type, filter, page, size);
          }}
          clearAllFilter={() => {}}
          onClickRow={(item, index) => {
            setSelectedItem(item);
            props.onClickRow && props.onClickRow(item, index);
          }}
        />
      </div>
    </>
  );
});
