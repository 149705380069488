export class PendingPanelApproval {
  _id: string;
  pId: number;
  name: string;
  age: string;
  sex: string;
  dob: Date;
  patientMobileNo: string;
  doctorId: string;
  doctorMobileNo: number;
  registrationLocation: string;
  contactNo: string;
  history: boolean;
  historyDetails: string;
  labId: number;
  sampleId: number;
  sampleType: string;
  containerId: string;
  panel: string;
  dueDate: Date;
  status: string;
  comments: string;
  pLab: string;
  department: string;
  test: string;
  analyte: string;
  result: string;
  final: string;
  abnFlag: boolean;
  critical: boolean;
  colorScheme: any; // Added colorScheme
  units: string;
  refRangesList: any;
  conclusion: string;
  loNor: string;
  hiNor: string;
  resultType: string;
  rangeType: string;
  panelCode: string;
  testCode: string;
  testName: string;
  analyteName: string;
  analyteCode: string;
  resultDate: Date;
  remarks: string;
  deltaFlag: boolean;
  deltaValue: string;
  resultStatus: string;
  testStatus: string;
  approvalDate: Date;
  approvalStatus: string;
  autoRelease: boolean;
  isResultUpdate: boolean;
  patientOrderId: string;
  patientResultId: string;
  reportPriority: string;
  deliveryMode: any; // Added deliveryMode
  mode: string; // Added mode
  validationLevel: number;
  calculationFlag: boolean;
  calculationVariable: string;
  calculationFormula: string;
  calcyName: string;
  reportable: boolean;
  showRanges: boolean;
  resultOrder: number;
  enteredBy: string;
  companyCode: string; // Added companyCode
  environment: string;
  documentType: string;
  dateOfEntry: Date;
  lastUpdated: Date;

  static types = {
    _id: 'string',
    pId: 'number',
    name: 'string',
    age: 'string',
    sex: 'string',
    dob: 'Date',
    patientMobileNo: 'string',
    doctorId: 'string',
    doctorMobileNo: 'number',
    registrationLocation: 'string',
    contactNo: 'string',
    history: 'boolean',
    historyDetails: 'string',
    labId: 'number',
    sampleId: 'number',
    sampleType: 'string',
    containerId: 'string',
    panel: 'string',
    dueDate: 'Date',
    status: 'string',
    comments: 'string',
    pLab: 'string',
    department: 'string',
    test: 'string',
    analyte: 'string',
    result: 'string',
    final: 'string',
    abnFlag: 'boolean',
    critical: 'boolean',
    colorScheme: 'any', // Added colorScheme
    units: 'string',
    refRangesList: 'any',
    conclusion: 'string',
    loNor: 'string',
    hiNor: 'string',
    resultType: 'string',
    rangeType: 'string',
    panelCode: 'string',
    testCode: 'string',
    testName: 'string',
    analyteName: 'string',
    analyteCode: 'string',
    resultDate: 'Date',
    remarks: 'string',
    deltaFlag: 'boolean',
    deltaValue: 'string',
    resultStatus: 'string',
    testStatus: 'string',
    approvalDate: 'Date',
    approvalStatus: 'string',
    autoRelease: 'boolean',
    isResultUpdate: 'boolean',
    patientOrderId: 'string',
    patientResultId: 'string', // Added patientResultId
    reportPriority: 'string',
    deliveryMode: 'any', // Added deliveryMode
    mode: 'string', // Added mode
    validationLevel: 'number',
    calculationFlag: 'boolean',
    calculationVariable: 'string',
    calculationFormula: 'string',
    calcyName: 'string',
    reportable: 'boolean',
    showRanges: 'boolean',
    resultOrder: 'number',
    enteredBy: 'string',
    companyCode: 'string', // Added companyCode
    environment: 'string',
    documentType: 'string',
    dateOfEntry: 'Date',
    lastUpdated: 'Date',
  };

  constructor(rawData: { [key in string]: any }) {
    this._id = rawData._id;
    this.pId = rawData.pId;
    this.name = rawData.name;
    this.age = rawData.age;
    this.sex = rawData.sex;
    this.dob = rawData.dob;
    this.patientMobileNo = rawData.patientMobileNo;
    this.doctorId = rawData.doctorId;
    this.doctorMobileNo = rawData.doctorMobileNo;
    this.registrationLocation = rawData.registrationLocation;
    this.contactNo = rawData.contactNo;
    this.history = rawData.history;
    this.historyDetails = rawData.historyDetails;
    this.labId = rawData.labId;
    this.sampleId = rawData.sampleId;
    this.sampleType = rawData.sampleType;
    this.containerId = rawData.containerId;
    this.panel = rawData.panel;
    this.dueDate = rawData.dueDate;
    this.status = rawData.status;
    this.comments = rawData.comments;
    this.pLab = rawData.pLab;
    this.department = rawData.department;
    this.test = rawData.test;
    this.analyte = rawData.analyte;
    this.result = rawData.result;
    this.final = rawData.final;
    this.abnFlag = rawData.abnFlag;
    this.critical = rawData.critical;
    this.colorScheme = rawData.colorScheme; // Added colorScheme
    this.units = rawData.units;
    this.refRangesList = rawData.refRangesList;
    this.conclusion = rawData.conclusion;
    this.loNor = rawData.loNor;
    this.hiNor = rawData.hiNor;
    this.resultType = rawData.resultType;
    this.rangeType = rawData.rangeType;
    this.panelCode = rawData.panelCode;
    this.testCode = rawData.testCode;
    this.testName = rawData.testName;
    this.analyteName = rawData.analyteName;
    this.analyteCode = rawData.analyteCode;
    this.resultDate = rawData.resultDate;
    this.remarks = rawData.remarks;
    this.deltaFlag = rawData.deltaFlag;
    this.deltaValue = rawData.deltaValue;
    this.resultStatus = rawData.resultStatus;
    this.testStatus = rawData.testStatus;
    this.approvalDate = rawData.approvalDate;
    this.approvalStatus = rawData.approvalStatus;
    this.autoRelease = rawData.autoRelease;
    this.isResultUpdate = rawData.isResultUpdate;
    this.patientOrderId = rawData.patientOrderId;
    this.patientResultId = rawData.patientResultId; // Added patientResultId
    this.reportPriority = rawData.reportPriority;
    this.deliveryMode = rawData.deliveryMode; // Added deliveryMode
    this.mode = rawData.mode; // Added mode
    this.validationLevel = rawData.validationLevel;
    this.calculationFlag = rawData.calculationFlag;
    this.calculationVariable = rawData.calculationVariable;
    this.calculationFormula = rawData.calculationFormula;
    this.calcyName = rawData.calcyName;
    this.reportable = rawData?.reportable;
    this.showRanges = rawData?.showRanges;
    this.resultOrder = rawData?.resultOrder;
    this.enteredBy = rawData.enteredBy;
    this.companyCode = rawData.companyCode; // Added companyCode
    this.environment = rawData.environment;
    this.documentType = rawData.documentType;
    this.dateOfEntry = rawData.dateOfEntry;
    this.lastUpdated = rawData.lastUpdated;
  }
}
