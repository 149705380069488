import React, { useState } from 'react';
import {
  Tooltip,
  Icons,
  textFilter,
  sortCaret,
  TableBootstrap,
  DateRangeFilter,
  customFilter,
  Form,
} from '@/library/components';
import { Confirm } from '@/library/models';
import dayjs from 'dayjs';
import { lookupItems, lookupValue } from '@/library/utils';
import { MultiSelect } from 'react-restyle-components';
import { ModalFieldsUpdate, AutocompleteRoleSelect } from '..';
import FieldsTable from './fields-table-list.component';

let controlCode;
let controlName;
let moduleName;
let role;
let event;
let fields;
let name;
let label;
let environment;
let version;
let status;
let companyCode;
let dateCreation;
let fieldValue;

interface FieldWiseControlListProps {
  data: any;
  totalSize: number;
  extraData: any;
  isView?: boolean;
  isDelete?: boolean;
  isUpdate?: boolean;
  isExport?: boolean;
  isVersionUpgrade?: boolean;
  isDuplicate?: boolean;
  onDelete?: (selectedItem: Confirm) => void;
  onSelectedRow?: (selectedItem: any) => void;
  onUpdateFields?: (items: any, id: string) => void;
  onVersionUpgrade?: (item: any) => void;
  onDuplicate?: (item: any) => void;
  onPageSizeChange?: (page: number, totalSize: number) => void;
  onFilter?: (
    type: string,
    filter: any,
    page: number,
    totalSize: number,
  ) => void;
  onApproval: (record: any) => void;
  onSingleDirectUpdateField?: (
    value: any,
    dataField: string,
    id: string,
  ) => void;
}

const fieldTypeConfig = {
  moduleName: {
    type: 'array',
  },
  role: {
    type: 'array',
  },
  fields: {
    type: 'array',
    fields: ['field', 'fieldValue', 'label', 'id'],
  },
};

export const FieldWiseControlList = (props: FieldWiseControlListProps) => {
  const editorCell = (row: any) => {
    return row.status !== 'I' ? true : false;
  };
  const [modalFieldsUpdate, setModalFieldsUpdate] = useState<any>({
    visible: false,
  });
  const [selectedRowId, setSelectedRowId] = useState('');
  const [widthRefBox, setWidthRefBox] = useState('20px');

  return (
    <>
      <div className={`${props.isView ? 'shown' : 'hidden'}`}>
        <TableBootstrap
          id='_id'
          data={props.data}
          totalSize={props.totalSize}
          fieldTypeConfig={fieldTypeConfig}
          columns={[
            {
              dataField: '_id',
              text: 'Id',
              hidden: true,
              csvExport: false,
            },
            {
              dataField: 'controlCode',
              text: 'Control Code',
              sort: true,
              headerStyle: {
                fontSize: 0,
              },
              headerClasses: 'textHeader',
              sortCaret: (order, column) => sortCaret(order, column),
              filter: textFilter({
                placeholder: 'Control Code',
                getFilter: filter => {
                  controlCode = filter;
                },
              }),
              editable: false,
            },
            {
              dataField: 'controlName',
              text: 'Control Name',
              sort: true,
              headerStyle: {
                fontSize: 0,
              },
              headerClasses: 'textHeader',
              sortCaret: (order, column) => sortCaret(order, column),
              filter: textFilter({
                placeholder: 'Control Name',
                getFilter: filter => {
                  controlName = filter;
                },
              }),
              editable: false,
            },
            {
              dataField: 'moduleName',
              text: 'Module Name',
              sort: true,
              headerStyle: {
                fontSize: 0,
              },
              headerClasses: 'textHeader',
              sortCaret: (order, column) => sortCaret(order, column),
              csvFormatter: (col, row) =>
                row?.moduleName ? row?.moduleName[0] : '',
              filter: textFilter({
                placeholder: 'Module Name',
                getFilter: filter => {
                  moduleName = filter;
                },
              }),
              formatter: (cellContent, row) => (
                <div className='flex flex-wrap'>
                  <ul>
                    {row?.moduleName?.map(item => (
                      <li> {item} </li>
                    ))}
                  </ul>
                </div>
              ),
              editable: (content, row, rowIndex, columnIndex) =>
                editorCell(row),
              editorRenderer: (
                editorProps,
                value,
                row,
                column,
                rowIndex,
                columnIndex,
              ) => (
                <>
                  <MultiSelect
                    selectedItems={value}
                    options={props.extraData?.moduleList}
                    onSelect={moduleName => {
                      props.onUpdateFields &&
                        props.onUpdateFields({ moduleName }, row?._id);
                    }}
                  />
                </>
              ),
            },
            {
              dataField: 'role',
              text: 'Role',
              sort: true,
              headerStyle: {
                fontSize: 0,
              },
              headerClasses: 'textHeader',
              sortCaret: (order, column) => sortCaret(order, column),
              csvFormatter: (col, row) => (row?.role ? row?.role[0] : ''),
              filter: textFilter({
                placeholder: 'Role',
                getFilter: filter => {
                  role = filter;
                },
              }),
              formatter: (cellContent, row) => (
                <div className='flex flex-wrap'>
                  <ul>
                    {row.role?.map(item => (
                      <li> {item} </li>
                    ))}
                  </ul>
                </div>
              ),
              editable: (content, row, rowIndex, columnIndex) =>
                editorCell(row),
              editorRenderer: (
                editorProps,
                value,
                row,
                column,
                rowIndex,
                columnIndex,
              ) => (
                <>
                  {/* Later we will adding modal future */}
                  <AutocompleteRoleSelect
                    role={row?.role}
                    onUpdate={role => {
                      props.onUpdateFields &&
                        props.onUpdateFields({ role }, row?._id);
                    }}
                  />
                </>
              ),
            },

            {
              dataField: 'event',
              text: 'Event',
              sort: true,
              headerStyle: {
                fontSize: 0,
              },
              headerClasses: 'textHeader',
              sortCaret: (order, column) => sortCaret(order, column),
              filter: textFilter({
                placeholder: 'Event',
                getFilter: filter => {
                  event = filter;
                },
              }),
              editable: (content, row, rowIndex, columnIndex) =>
                editorCell(row),
              editorRenderer: (
                editorProps,
                value,
                row,
                column,
                rowIndex,
                columnIndex,
              ) => (
                <>
                  <select
                    value={row.event}
                    className={
                      'leading-4 p-2 focus:outline-none focus:ring block w-full shadow-sm sm:text-base border-2 rounded-md'
                    }
                    onChange={e => {
                      const event = e.target.value;
                      props.onUpdateFields &&
                        props.onUpdateFields({ event }, row?._id);
                    }}
                  >
                    <option>Select</option>
                    {lookupItems(props.extraData.lookupItems, 'EVENT').map(
                      (item: any, index: number) => (
                        <option key={index} value={item.code}>
                          {lookupValue(item)}
                        </option>
                      ),
                    )}
                  </select>
                </>
              ),
            },
            {
              dataField: 'fields',
              text: 'Fields',
              sort: true,
              headerStyle: {
                fontSize: 0,
              },
              style: { width: widthRefBox },
              headerClasses: 'textHeader',
              sortCaret: (order, column) => sortCaret(order, column),
              csvFormatter: (col, row) => '',
              filter: textFilter({
                placeholder: 'Fields',
                getFilter: filter => {
                  fields = filter;
                },
              }),
              editable: (content, row, rowIndex, columnIndex) =>
                editorCell(row),
              formatter: (cellContent, row) => (
                <>
                  <FieldsTable
                    row={row}
                    selectedRowId={selectedRowId}
                    setSelectedRowId={setSelectedRowId}
                    setWidthRefBox={setWidthRefBox}
                    setModalFieldsUpdate={setModalFieldsUpdate}
                  />
                </>
              ),
            },
            {
              dataField: 'version',
              text: 'Version',
              sort: true,
              headerStyle: {
                fontSize: 0,
              },
              headerClasses: 'textHeader',
              sortCaret: (order, column) => sortCaret(order, column),
              filter: textFilter({
                placeholder: 'Version',
                getFilter: filter => {
                  version = filter;
                },
              }),
              editable: false,
            },
            {
              dataField: 'dateCreation',
              editable: false,
              text: 'Date Creation',
              headerClasses: 'textHeader',
              // sort: true,
              headerStyle: {
                fontSize: 0,
              },
              // sortCaret: (order, column) => sortCaret(order, column),
              csvFormatter: (col, row) =>
                row.dateCreation
                  ? dayjs(row.dateCreation).format('YYYY-MM-DD')
                  : '',
              filter: customFilter({
                getFilter: filter => {
                  dateCreation = filter;
                },
              }),
              filterRenderer: (onFilter, column) => (
                <DateRangeFilter onFilter={onFilter} column={column} />
              ),
              formatter: (cell, row) => {
                return (
                  <>{dayjs(row.dateCreation).format('DD-MM-YYYY HH:mm:ss')}</>
                );
              },
              // editorR
            },
            {
              dataField: 'status',
              text: 'Status',
              sort: true,
              headerClasses: 'textHeader',
              headerStyle: {
                fontSize: 0,
              },
              // editable: (content, row, rowIndex, columnIndex) =>
              //   row.status == 'D' || row.status == 'I' ? false : true,
              editable: true,
              sortCaret: (order, column) => sortCaret(order, column),
              filter: textFilter({
                placeholder: 'Status',
                getFilter: filter => {
                  status = filter;
                },
              }),
              editorRenderer: (
                editorProps,
                value,
                row,
                column,
                rowIndex,
                columnIndex,
              ) => (
                <>
                  <select
                    value={row.status}
                    className={
                      'leading-4 p-2 focus:outline-none focus:ring block w-full shadow-sm sm:text-base border-2 rounded-md'
                    }
                    onChange={e => {
                      const status = e.target.value;
                      props.onUpdateFields &&
                        props.onUpdateFields({ status }, row?._id);
                    }}
                  >
                    <option>Select</option>
                    {lookupItems(props.extraData.lookupItems, 'STATUS')
                      .filter(item => item.code != 'D')
                      .map((item: any, index: number) => (
                        <option key={index} value={item.code}>
                          {lookupValue(item)}
                        </option>
                      ))}
                  </select>
                </>
              ),
            },
            {
              text: 'Company Code',
              dataField: 'companyCode',
              sort: true,
              headerStyle: {
                fontSize: 0,
              },
              sortCaret: (order, column) => sortCaret(order, column),
              editable: false,

              filter: textFilter({
                placeholder: 'Company Code',
                getFilter: filter => {
                  companyCode = filter;
                },
              }),
              headerClasses: 'textHeader2',
            },
            {
              dataField: 'environment',
              text: 'Environment',
              sort: true,
              headerStyle: {
                fontSize: 0,
              },
              headerClasses: 'textHeader',
              sortCaret: (order, column) => sortCaret(order, column),
              filter: textFilter({
                placeholder: 'Environment',
                getFilter: filter => {
                  environment = filter;
                },
              }),
              editable: false,
            },
            {
              dataField: 'operation',
              text: 'Action',
              editable: false,
              csvExport: false,
              // hidden: !props.isDelete,
              formatter: (cellContent, row) => (
                <>
                  <div className='flex flex-row'>
                    <Tooltip tooltipText='Delete'>
                      <Icons.IconContext
                        color='#ffffff'
                        size='20'
                        onClick={() =>
                          props.onDelete &&
                          props.onDelete({
                            type: 'delete',
                            show: true,
                            id: [row._id],
                            title: 'Are you sure?',
                            body: 'Do you want to delete this record?',
                          })
                        }
                      >
                        {Icons.getIconTag(Icons.IconBs.BsFillTrashFill)}
                      </Icons.IconContext>
                    </Tooltip>
                    {row.status == 'D' && (
                      <Tooltip tooltipText='Approval'>
                        <Icons.RIcon
                          nameIcon='AiOutlineCheckCircle'
                          propsIcon={{ size: 24, color: '#ffffff' }}
                          onClick={() => props.onApproval(row)}
                        />
                      </Tooltip>
                    )}
                  </div>
                </>
              ),
              headerClasses: 'sticky right-0  bg-gray-500 text-white z-50',
              classes: (cell, row, rowIndex, colIndex) => {
                return 'sticky right-0 bg-gray-500';
              },
              style: (cell, row, rowIndex, colIndex) => {
                return {
                  zIndex: props.data?.length - rowIndex,
                };
              },
            },
          ]}
          isDelete={props.isDelete}
          isEditModify={props.isUpdate}
          isExport={props.isExport}
          isSelectRow={true}
          fileName='Field Wise Control'
          onSelectedRow={rows => {
            props.onSelectedRow &&
              props.onSelectedRow(rows.map((item: any) => item._id));
          }}
          onUpdateItem={(value: any, dataField: string, id: string) => {
            props.onUpdateFields &&
              props.onUpdateFields({ [dataField]: value }, id);
          }}
          onPageSizeChange={(page, size) => {
            props.onPageSizeChange && props.onPageSizeChange(page, size);
          }}
          onFilter={(type, filter, page, size) => {
            props.onFilter && props.onFilter(type, filter, page, size);
          }}
          clearAllFilter={() => {}}
          hideExcelSheet={['_id', 'operation']}
          dynamicStylingFields={['status', 'environment']}
        />
        <ModalFieldsUpdate
          {...modalFieldsUpdate}
          onUpdate={(fields: any) => {
            setModalFieldsUpdate({ visible: false });
            props.onUpdateFields &&
              props.onUpdateFields({ fields }, modalFieldsUpdate?._id);
          }}
          onClose={() => {
            setModalFieldsUpdate({ visible: false });
          }}
        />
      </div>
    </>
  );
};
