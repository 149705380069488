export class TemplatePatientResult {
  _id: string;
  reportTemplateType: string;
  reportBody: object;
  templateCode: string;
  templateTitle: string;
  reportOrder: number;
  templateFor: string;
  endOfPage: any[];
  endOfReport: any[];
  companyCode: string; // Added companyCode
  environment: string; // Added environment
  status: string;
  documentType: string;
  dateOfEntry: Date;
  lastUpdated: Date;

  static types = {
    _id: 'string',
    reportTemplateType: 'string',
    reportBody: 'object',
    templateCode: 'string',
    templateTitle: 'string',
    reportOrder: 'number',
    templateFor: 'string',
    endOfPage: 'Array',
    endOfReport: 'Array',
    companyCode: 'string', // Added companyCode
    environment: 'string', // Added environment
    status: 'string',
    documentType: 'string',
    dateOfEntry: 'Date',
    lastUpdated: 'Date',
  };

  constructor(rawData: { [key in string]: any }) {
    this._id = rawData._id;
    this.reportTemplateType = rawData.reportTemplateType;
    this.reportBody = rawData.reportBody;
    this.templateCode = rawData.templateCode;
    this.templateTitle = rawData.templateName;
    this.reportOrder = rawData.reportOrder;
    this.templateFor = rawData.templateFor;
    this.endOfPage = rawData.endOfPage;
    this.endOfReport = rawData.endOfReport;
    this.documentType = rawData.documentType;
    this.companyCode = rawData.companyCode; // Added companyCode
    this.environment = rawData.environment; // Added environment
    this.status = rawData.status;
    this.dateOfEntry = rawData.dateOfEntry;
    this.lastUpdated = rawData.lastUpdated;
  }
}

export class SelectedItemsTemplatePatientResult {
  endOfPage: any[];
  endOfReport: any[];
  constructor(rawData: { [key in string]: any }) {
    this.endOfPage = rawData.endOfPage;
    this.endOfReport = rawData.endOfReport;
  }
}
