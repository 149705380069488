/**
 * @fileoverview Use this file invoke LimsPlus API
 * implementation related to LimsPlus standards
 
 * @author limsplus
 */
import { client, ServiceResponse } from '@/core-services/graphql/apollo-client';
import { stores } from '@/stores';
import { CREATE } from './mutation';

export class InstrumentMasterService {
  //   list = (page = 0, limit = 10) =>
  //     new Promise<any>((resolve, reject) => {
  //       client
  //         .mutate({
  //           mutation: LIST,
  //           variables: { input: { page, limit } },
  //         })
  //         .then((response: any) => {
  //           stores.interfaceManagerStore.updateInterfaceManagerList(
  //             response.data,
  //           );
  //           resolve(response.data);
  //         })
  //         .catch((error) =>
  //           reject(new ServiceResponse<any>(0, error.message, undefined)),
  //         );
  //     });

  create = (variables: any) =>
    new Promise<any>((resolve, reject) => {
      client
        .mutate({
          mutation: CREATE,
          variables,
        })
        .then((response: any) => {
          resolve(response.data);
        })
        .catch((error) =>
          reject(new ServiceResponse<any>(0, error.message, undefined)),
        );
    });

  //   checkExitsEnvCode = (variables: any) =>
  //     new Promise<any>((resolve, reject) => {
  //       client
  //         .mutate({
  //           mutation: CHECK_EXISTS_RECORD,
  //           variables,
  //         })
  //         .then((response: any) => {
  //           resolve(response.data);
  //         })
  //         .catch((error) =>
  //           reject(new ServiceResponse<any>(0, error.message, undefined)),
  //         );
  //     });

  //   deleteInterfaceManager = (variables: any) =>
  //     new Promise<any>((resolve, reject) => {
  //       client
  //         .mutate({
  //           mutation: REMOVE_RECORD,
  //           variables,
  //         })
  //         .then((response: any) => {
  //           resolve(response.data);
  //         })
  //         .catch((error) =>
  //           reject(new ServiceResponse<any>(0, error.message, undefined)),
  //         );
  //     });

  //   update = (variables: any) =>
  //     new Promise<any>((resolve, reject) => {
  //       client
  //         .mutate({
  //           mutation: UPDATE_RECORD,
  //           variables,
  //         })
  //         .then((response: any) => {
  //           resolve(response.data);
  //         })
  //         .catch((error) =>
  //           reject(new ServiceResponse<any>(0, error.message, undefined)),
  //         );
  //     });

  //   filter = (variables: any) =>
  //     new Promise<any>((resolve, reject) => {
  //       stores.uploadLoadingFlag(false);
  //       client
  //         .mutate({
  //           mutation: FILTER,
  //           variables,
  //         })
  //         .then((response: any) => {
  //           if (!response.data.filterInterfaceManagers.success)
  //             return this.listInterfaceManager();
  //           stores.interfaceManagerStore.filterInterfaceManager(response.data);
  //           stores.uploadLoadingFlag(true);
  //           resolve(response.data);
  //         })
  //         .catch((error) =>
  //           reject(new ServiceResponse<any>(0, error.message, undefined)),
  //         );
  //     });

  //   filterByFields = (variables: any) =>
  //     new Promise<any>((resolve, reject) => {
  //       stores.uploadLoadingFlag(false);
  //       client
  //         .mutate({
  //           mutation: FILTER_BY_FIELDS,
  //           variables,
  //         })
  //         .then((response: any) => {
  //           if (!response.data.filterByFieldsInterfaceManagers.success)
  //             return this.listInterfaceManager();
  //           stores.interfaceManagerStore.filterInterfaceManager({
  //             filterInterfaceManagers: {
  //               data: response.data.filterByFieldsInterfaceManagers.data,
  //               paginatorInfo: {
  //                 count:
  //                   response.data.filterByFieldsInterfaceManagers.paginatorInfo
  //                     .count,
  //               },
  //             },
  //           });
  //           stores.uploadLoadingFlag(true);
  //           resolve(response.data);
  //         })
  //         .catch((error) =>
  //           reject(new ServiceResponse<any>(0, error.message, undefined)),
  //         );
  //     });

  //   findByFields = (variables: any) =>
  //     new Promise<any>((resolve, reject) => {
  //       client
  //         .mutate({
  //           mutation: FIND_BY_FIELDS,
  //           variables,
  //         })
  //         .then((response: any) => {
  //           resolve(response.data);
  //         })
  //         .catch((error) =>
  //           reject(new ServiceResponse<any>(0, error.message, undefined)),
  //         );
  //     });
}
