import React from 'react';
import ReactDOM from 'react-dom/client';
import { Font } from '@react-pdf/renderer';
import './index.css';
// import './global.scss';
import App from './App';
// import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
// if ('serviceWorker' in navigator) {
//   navigator.serviceWorker
//     .register('./firebase-messaging-sw.js')
//     .then(function (registration) {
//       console.log('Registration successful, scope is:', registration.scope);
//     })
//     .catch(function (err) {
//       console.log('Service worker registration failed, error:', err);
//     });
// }

Font.register({
  family: 'ArimaBold',
  fonts: [
    {
      src: '/assets/fonts/arima/arima-bold.ttf',
      fontStyle: 'normal',
      fontWeight: 'bold',
    },
  ],
});

Font.register({
  family: 'ArimaRegular',
  fonts: [
    {
      src: '/assets/fonts/arima/arima-regular.ttf',
      fontStyle: 'normal',
    },
  ],
});

Font.register({
  family: 'IBMPlexSans',
  fonts: [
    {
      src: '/assets/fonts/IBM_Plex_Sans/IBMPlexSans-Bold.ttf',
      fontStyle: 'normal',
      fontWeight: 'bold',
    },
    {
      src: '/assets/fonts/IBM_Plex_Sans/IBMPlexSans-Italic.ttf',
      fontWeight: 'normal',
      fontStyle: 'italic',
    },
  ],
});

Font.register({
  family: 'IBMPlexSansBold',
  src: '/assets/fonts/IBM_Plex_Sans/IBMPlexSans-Bold.ttf',
  fontStyle: 'normal',
  fontWeight: 'bold',
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <div>
    <App />
  </div>,
);

// serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
