export class InstrumentMaster {
  _id: string;
  lab: string;
  department: string;
  instType: string;
  instId: string;
  ipAddress: string;
  portNo: number;
  enteredBy: string;
  status: string;
  companyCode: string;
  environment: string;
  dateOfEntry: Date;
  lastUpdated: Date;

  static types = {
    _id: 'string',
    lab: 'string',
    department: 'string',
    instType: 'string',
    instId: 'string',
    ipAddress: 'string',
    portNo: 'number',
    enteredBy: 'string',
    status: 'string',
    companyCode: 'string',
    environment: 'string',
    dateOfEntry: 'Date',
    lastUpdated: 'Date',
  };

  constructor(rawData: { [key in string]: any }) {
    this._id = rawData._id;
    this.lab = rawData.lab;
    this.department = rawData.department;
    this.instType = rawData.instType;
    this.instId = rawData.instId;
    this.ipAddress = rawData.ipAddress;
    this.portNo = rawData.portNo;
    this.enteredBy = rawData.enteredBy;
    this.status = rawData.status;
    this.companyCode = rawData.companyCode;
    this.environment = rawData.environment;
    this.dateOfEntry = rawData.dateOfEntry;
    this.lastUpdated = rawData.lastUpdated;
  }
}
