import React, { useRef } from 'react';
import { PdfFooterView, PdfSmall } from '@components';
import { Image, View } from '@react-pdf/renderer';

interface PdfPageBrandingFooterProps {
  data: any;
}

export const PdfPageBrandingFooter = ({ data }: PdfPageBrandingFooterProps) => {
  const mainBoxCSS = useRef<any>({});
  const titleCSS = useRef<any>({});
  const subTitleCSS = useRef<any>({});
  if (data.pageBranding?.footer?.mainBoxCSS) {
    try {
      mainBoxCSS.current = eval(
        '({' + data.pageBranding?.footer?.mainBoxCSS + '})',
      );
    } catch (e) {
      mainBoxCSS.current = {};
    }
  }
  if (data.pageBranding?.footer?.titleCSS) {
    try {
      titleCSS.current = eval(
        '({' + data.pageBranding?.footer?.titleCSS + '})',
      );
    } catch (e) {
      titleCSS.current = {};
    }
  }
  if (data.pageBranding?.footer?.subTitleCSS) {
    try {
      subTitleCSS.current = eval(
        '({' + data.pageBranding?.footer?.subTitleCSS + '})',
      );
    } catch (e) {
      subTitleCSS.current = {};
    }
  }

  return (
    <PdfFooterView fixed style={{ ...mainBoxCSS.current }}>
      {data.pageBranding?.footer?.backgroundImage && (
        <>
          <View
            style={{
              position: 'absolute',
              bottom: 0,
              left: 0,
              right: 0,
              top: 0,
            }}
            fixed={true}
          >
            <Image
              object-fit='fill'
              style={{
                position: 'absolute',
                bottom: 0,
                left: 0,
                right: 0,
                top: 0,
                objectFit: 'fill',
              }}
              source={{
                uri:
                  typeof data.pageBranding?.footer?.backgroundImage == 'object'
                    ? data.pageBranding?.footer?.backgroundImageBase64
                    : data.pageBranding?.footer?.backgroundImage,
                method: 'GET',
                headers: { 'Cache-Control': 'no-cache' },
                body: '',
              }}
            />
          </View>
        </>
      )}
      <PdfSmall style={{ textAlign: 'center', ...titleCSS.current }}>
        {data.pageBranding?.footer?.title || ''}
      </PdfSmall>
      <PdfSmall style={{ ...subTitleCSS.current }}>{`${
        data.pageBranding?.footer?.subTitle || ''
      }`}</PdfSmall>
    </PdfFooterView>
  );
};
