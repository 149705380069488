import { PriceList } from '@/models';
export class CorporateClients {
  _id: string;
  existsVersionId: string;
  existsRecordId: string;
  corporateCode: string;
  corporateName: string;
  invoiceAc: number;
  priceList: Array<PriceList>;
  clientSpecificPrice: boolean;
  acType: string;
  acClass: string;
  billingOn: string;
  billingFrequency: string;
  customerGroup: string;
  category: string;
  country: string;
  state: string;
  district: string;
  city: string;
  area: string;
  postalCode: number | string;
  address: string;
  creditLimit: number;
  consumedLimit: number;
  salesTerritoRy: string;
  sbu: string;
  zone: string;
  telephone: string;
  mobileNo: string;
  email: string;
  reportTo: Array<any>;
  reportToMobiles: Array<any>;
  reportToEmails: Array<any>;
  reportPriority: string;
  deliveryMode: Array<any>;
  schedule: string;
  info: string;
  fyiLine: string;
  workLine: string;
  confidential: boolean;
  urgent: boolean;
  reportFormat: boolean;
  isEmployeeCode: boolean;
  specificFormat: boolean;
  isBalanceCheck: boolean;
  isPredefinedPanel: boolean;
  panelList: Array<any>;
  templateForImport: string;
  templateForExport: string;
  billingTemplate: string;
  dateCreation: Date;
  dateActive: Date;
  dateExpire: Date;
  version: number;
  enteredBy: string;
  companyCode: string;
  status: string;
  environment: string;
  dateOfEntry: Date;
  lastUpdated: Date;

  static types = {
    _id: 'string',
    existsVersionId: 'string',
    existsRecordId: 'string',
    corporateCode: 'string',
    corporateName: 'string',
    invoiceAc: 'number',
    priceList: 'Array',
    clientSpecificPrice: 'boolean',
    acType: 'string',
    acClass: 'string',
    billingOn: 'string',
    billingFrequency: 'string',
    customerGroup: 'string',
    category: 'string',
    country: 'string',
    state: 'string',
    district: 'string',
    city: 'string',
    area: 'string',
    postalCode: 'number',
    address: 'string',
    creditLimit: 'number',
    consumedLimit: 'number',
    salesTerritoRy: 'string',
    sbu: 'string',
    zone: 'string',
    telephone: 'string',
    mobileNo: 'string',
    email: 'string',
    reportTo: 'Array',
    reportToMobiles: 'Array',
    reportToEmails: 'Array',
    reportPriority: 'string',
    deliveryMode: 'Array',
    schedule: 'string',
    info: 'string',
    fyiLine: 'string',
    workLine: 'string',
    confidential: 'boolean',
    urgent: 'boolean',
    reportFormat: 'boolean',
    isEmployeeCode: 'boolean',
    specificFormat: 'boolean',
    isBalanceCheck: 'boolean',
    isPredefinedPanel: 'boolean',
    panelList: 'Array',
    templateForImport: 'string',
    templateForExport: 'string',
    billingTemplate: 'string',
    dateCreation: 'Date',
    dateActive: 'Date',
    dateExpire: 'Date',
    version: 'number',
    enteredBy: 'string',
    companyCode: 'string',
    status: 'string',
    environment: 'string',
    dateOfEntry: 'Date',
    lastUpdated: 'Date',
  };

  constructor(rawData: { [key in string]: any }) {
    this._id = rawData._id;
    this.existsVersionId = rawData.existsVersionId;
    this.existsRecordId = rawData.existsRecordId;
    this.corporateCode = rawData.corporateCode;
    this.corporateName = rawData.corporateName;
    this.invoiceAc = rawData.invoiceAc;
    this.priceList = rawData.priceList;
    this.clientSpecificPrice = rawData.clientSpecificPrice;
    this.acType = rawData.acType;
    this.acClass = rawData.acClass;
    this.billingOn = rawData.billingOn;
    this.billingFrequency = rawData.billingFrequency;
    this.customerGroup = rawData.customerGroup;
    this.category = rawData.category;
    this.country = rawData.country;
    this.state = rawData.state;
    this.district = rawData.district;
    this.city = rawData.city;
    this.area = rawData.area;
    this.postalCode = rawData.postalCode;
    this.address = rawData.address;
    this.creditLimit = rawData.creditLimit;
    this.consumedLimit = rawData.consumedLimit;
    this.salesTerritoRy = rawData.salesTerritoRy;
    this.sbu = rawData.sbu;
    this.zone = rawData.zone;
    this.telephone = rawData.telephone;
    this.mobileNo = rawData.mobileNo;
    this.email = rawData.email;
    this.reportTo = rawData.reportTo;
    this.reportToMobiles = rawData.reportToMobiles;
    this.reportToEmails = rawData.reportToEmails;
    this.reportPriority = rawData.reportPriority;
    this.deliveryMode = rawData.deliveryMode;
    this.schedule = rawData.schedule;
    this.info = rawData.info;
    this.fyiLine = rawData.fyiLine;
    this.workLine = rawData.workLine;
    this.confidential = rawData.confidential;
    this.urgent = rawData.urgent;
    this.reportFormat = rawData.reportFormat;
    this.isEmployeeCode = rawData.isEmployeeCode;
    this.specificFormat = rawData.specificFormat;
    this.isBalanceCheck = rawData.isBalanceCheck;
    this.isPredefinedPanel = rawData.isPredefinedPanel;
    this.panelList = rawData.panelList;
    this.templateForImport = rawData.templateForImport;
    this.templateForExport = rawData.templateForExport;
    this.billingTemplate = rawData.billingTemplate || 'Template_1';
    this.dateCreation = rawData.dateCreation;
    this.dateActive = rawData.dateActive;
    this.dateExpire = rawData.dateExpire;
    this.version = rawData.version;
    this.enteredBy = rawData.enteredBy;
    this.companyCode = rawData.companyCode;
    this.status = rawData.status;
    this.environment = rawData.environment;
    this.dateOfEntry = rawData.dateOfEntry;
    this.lastUpdated = rawData.lastUpdated;
  }
}

export class SelectedItems {
  deliveryMode: any[];
  panelList: any[];
  constructor(rawData: { [key in string]: any }) {
    this.deliveryMode = rawData.deliveryMode;
    this.panelList = rawData.panelList;
  }
}
