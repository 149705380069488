import React, { useState, useMemo, useEffect } from 'react';
import { observer } from 'mobx-react';
import _ from 'lodash';
import {
  Toast,
  Buttons,
  ModalConfirm,
  List,
  Svg,
  ModalImportFile,
  MainPageHeading,
  ManualImportTabs,
  StaticInputTable,
  ImportFile,
} from '@/library/components';
import * as XLSX from 'xlsx';
import {
  InstResultMappingInputTable,
  InstResultMappingList,
} from '../components';
import { useForm } from 'react-hook-form';
import { useStores } from '@/stores';
import { getDefaultLookupItem } from '@/library/utils';
import { RouterFlow } from '@/flows';
import { toJS } from 'mobx';
import { InstResultMappingHoc } from '../hoc';

const InstResultMapping = InstResultMappingHoc(
  observer((props) => {
    const { initialLoad } = props;
    const {
      loginStore,
      instResultMappingStore,
      routerStore,
      testAnalyteMappingStore,
      segmentMappingStore,
      testMasterStore,
    } = useStores();

    const {
      control,
      handleSubmit,
      formState: { errors },
      setValue,
    } = useForm();

    const [modalImportFile, setModalImportFile] = useState({});
    const [isInputView, setInputView] = useState<boolean>(false);
    const [isImport, setIsImport] = useState<boolean>(false);
    const [arrImportRecords, setArrImportRecords] = useState<Array<any>>([]);
    const [modalConfirm, setModalConfirm] = useState<any>();
    const [pLabs, setPLabs] = useState<Array<string>>();
    const [departments, setDepartments] = useState<Array<string>>();
    const [instTypes, setInstTypes] = useState<Array<string>>();

    const getInstTypes = () => {
      segmentMappingStore.segmentMappingService
        .fetchKeyValue({ input: { key: 'instType' } })
        .then((res) => {
          if (res.fetchKeyValueSegmentMapping.success) {
            setInstTypes(res.fetchKeyValueSegmentMapping.result);
          }
        });
    };

    const getLabFromAnalyteMapping = () => {
      testAnalyteMappingStore.testAnalyteMappingService
        .fetchKeyValue({
          input: { key: 'lab' },
        })
        .then((res) => {
          if (res.fetchKeyValueTestAnalyteMapping.success) {
            setPLabs(res.fetchKeyValueTestAnalyteMapping.result);
          }
        });
    };

    const getDepartments = () => {
      testMasterStore.testMasterService
        .findDistinct({ input: { filter: { field: 'department' } } })
        .then((res) => {
          if (res.findDistinctTestMaster.success) {
            setDepartments(res.findDistinctTestMaster.result);
          }
        });
    };

    useEffect(() => {
      initialLoad();
      getLabFromAnalyteMapping();
      getDepartments();
      getInstTypes();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleFileUpload = (file: any) => {
      const reader = new FileReader();
      reader.addEventListener('load', (evt: any) => {
        /* Parse data */
        const bstr = evt.target.result;
        const wb = XLSX.read(bstr, { type: 'binary' });
        /* Get first worksheet */
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data = XLSX.utils.sheet_to_json(ws, { raw: true });
        const list = data.map((item: any) => {
          return {
            key: item.Key,
            pLab: item.PLab,
            department: item.Department,
            instType: item['Inst Type'],
            instId: item['Inst Id'],
            assayCode: item['Assay Code'],
            instTest: item['Inst Test'],
            environment: loginStore.login?.environment,
            companyCode: loginStore.login?.companyCode,
            status: 'D',
          };
        });
        setArrImportRecords(list);
      });
      reader.readAsBinaryString(file);
    };

    const onSubmitInstResultMapping = () => {
      const requiredKeys = ['pLab', 'department', 'instType', 'instId'];
      const isKeysAvailable = requiredKeys.every((key) =>
        instResultMappingStore.instResultMapping?.some((item) => key in item),
      );
      if (!isKeysAvailable && !isImport)
        return Toast.error({
          message: '😔 Please enter all information!',
        });
      instResultMappingStore.instResultMappingService
        .addInstResultMapping({
          input: {
            filter: {
              instResultMapping: isImport
                ? arrImportRecords
                : instResultMappingStore.instResultMapping,
            },
          },
        })
        .then((res) => {
          if (res.createInstrumentResultMapping.success) {
            Toast.success({
              message: `😊 ${res.createInstrumentResultMapping.message}`,
            });
            instResultMappingStore.instResultMappingService.listInstResultMapping();
            initialLoad();
            setArrImportRecords([]);
            setIsImport(false);
            setInputView(!isInputView);
          }
        });
    };

    const addItem = () => {
      const instResultMapping = instResultMappingStore.instResultMapping;
      instResultMapping?.push({
        index: instResultMapping?.length + 1,
        pLab:
          instResultMapping?.length == 1
            ? instResultMapping[instResultMapping.length - 1].pLab
            : undefined,
        department:
          instResultMapping?.length == 1
            ? instResultMapping[instResultMapping.length - 1].department
            : undefined,
        instType:
          instResultMapping?.length == 1
            ? instResultMapping[instResultMapping.length - 1].instType
            : undefined,
        instId:
          instResultMapping?.length == 1
            ? instResultMapping[instResultMapping.length - 1].instId
            : undefined,
        environment: getDefaultLookupItem(
          routerStore.lookupItems,
          'ENVIRONMENT',
        ),
      });
      instResultMappingStore.updateInstResultMapping(instResultMapping);
    };

    const getTestDetails = (lab) => {
      return testAnalyteMappingStore.testAnalyteMappingService
        .findByFileds(
          {
            input: {
              filter: { lab },
            },
          },
          true,
        )
        .then((res) => {
          if (res.findByFiledsTestAnalyteMappings.success) {
            return res.findByFiledsTestAnalyteMappings.data;
          }
        });
    };

    const getAnalyteDetails = (testCode) => {
      return testAnalyteMappingStore.testAnalyteMappingService
        .findByFileds(
          {
            input: {
              filter: { testCode },
            },
          },
          true,
        )
        .then((res) => {
          if (res.findByFiledsTestAnalyteMappings.success) {
            return res.findByFiledsTestAnalyteMappings.data;
          }
        });
    };

    const inputTableInstResultMapping = useMemo(
      () =>
        instResultMappingStore.instResultMapping?.length > 0 && (
          <>
            <div className='p-2 rounded-lg shadow-xl'>
              <InstResultMappingInputTable
                addItem={() => addItem()}
                getTestDetails={(lab) => getTestDetails(lab)}
                getAnalyteDetails={(testCode) => getAnalyteDetails(testCode)}
                data={instResultMappingStore.instResultMapping}
                extraData={{
                  pLabs,
                  departments,
                  instTypes,
                  lookupItems: routerStore.lookupItems,
                }}
                onUpdateItems={(items, index) => {
                  const position = _.findIndex(
                    instResultMappingStore.instResultMapping,
                    {
                      index,
                    },
                  );
                  const instResultMapping =
                    instResultMappingStore.instResultMapping;
                  instResultMapping[position] = {
                    ...instResultMapping[position],
                    ...items,
                  };
                  instResultMappingStore.updateInstResultMapping(
                    instResultMapping,
                  );
                }}
                onDelete={(index) => {
                  const position = _.findIndex(
                    instResultMappingStore.instResultMapping,
                    {
                      index,
                    },
                  );
                  const firstArr =
                    instResultMappingStore.instResultMapping?.slice(
                      0,
                      position,
                    ) || [];
                  const secondArr =
                    instResultMappingStore.instResultMapping?.slice(
                      position + 1,
                    ) || [];
                  const finalArray = [...firstArr, ...secondArr];
                  instResultMappingStore.updateInstResultMapping(finalArray);
                }}
                onDuplicate={(item) => {
                  const instResultMapping =
                    instResultMappingStore.instResultMapping;
                  instResultMapping?.push({
                    ...item,
                    index: instResultMapping?.length + 1,
                  });
                  instResultMappingStore.updateInstResultMapping(
                    instResultMapping,
                  );
                }}
              />
            </div>
          </>
        ),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [JSON.parse(JSON.stringify(instResultMappingStore.instResultMapping))],
    );

    return (
      <>
        <MainPageHeading
          title={routerStore.selectedComponents?.title || ''}
          store={loginStore}
        />
        <div
          className='flex justify-end'
          style={{
            position: 'fixed',
            right: '30px',
            top: '135px',
            zIndex: 9999,
          }}
        >
          {RouterFlow.checkPermission(routerStore.userPermission, 'Add') && (
            <Buttons.ButtonCircleAddRemoveBottom
              show={!isInputView}
              onClick={() => setInputView(!isInputView)}
            />
          )}
        </div>
        <div className=' mx-auto flex-wrap'>
          <div
            className={
              'p-2 rounded-lg shadow-xl ' + (isInputView ? 'show' : 'hidden')
            }
          >
            <ManualImportTabs
              isImport={isImport}
              isImportDisable={
                !RouterFlow.checkPermission(
                  toJS(routerStore.userPermission),
                  'Import',
                )
              }
              onClick={(flag) => {
                setIsImport(flag);
              }}
            />
            {!isImport ? (
              inputTableInstResultMapping
            ) : (
              <>
                {arrImportRecords?.length > 0 ? (
                  <StaticInputTable data={arrImportRecords} />
                ) : (
                  <ImportFile
                    onClick={(file) => {
                      handleFileUpload(file[0]);
                    }}
                  />
                )}
              </>
            )}
            <br />
            <List direction='row' space={3} align='center'>
              <Buttons.Button
                size='medium'
                type='solid'
                icon={Svg.Save}
                onClick={handleSubmit(onSubmitInstResultMapping)}
              >
                Save
              </Buttons.Button>

              <Buttons.Button
                size='medium'
                type='outline'
                icon={Svg.Remove}
                onClick={() => {
                  window.location.reload();
                }}
              >
                Clear
              </Buttons.Button>
            </List>
          </div>
        </div>
        <div className='p-2 rounded-lg shadow-xl overflow-scroll'>
          <InstResultMappingList
            data={instResultMappingStore.instResultMappingList || []}
            totalSize={instResultMappingStore.instResultMappingListCount}
            getTestDetails={(lab) => getTestDetails(lab)}
            getAnalyteDetails={(testCode) => getAnalyteDetails(testCode)}
            extraData={{
              pLabs,
              departments,
              instTypes,
              lookupItems: routerStore.lookupItems,
            }}
            isView={RouterFlow.checkPermission(
              routerStore.userPermission,
              'View',
            )}
            isDelete={RouterFlow.checkPermission(
              routerStore.userPermission,
              'Delete',
            )}
            isUpdate={RouterFlow.checkPermission(
              routerStore.userPermission,
              'Update',
            )}
            isExport={RouterFlow.checkPermission(
              routerStore.userPermission,
              'Export',
            )}
            onDelete={(selectedItem) => setModalConfirm(selectedItem)}
            onSelectedRow={(rows) => {
              setModalConfirm({
                show: true,
                type: 'delete',
                id: rows,
                title: 'Are you sure?',
                body: 'Do you want to delete selected record?',
              });
            }}
            onUpdateItems={(fields: any, id: string) => {
              setModalConfirm({
                show: true,
                type: 'updateFields',
                data: { fields, id },
                title: 'Are you sure?',
                body: 'Update records',
              });
            }}
            onPageSizeChange={(page, limit) => {
              instResultMappingStore.instResultMappingService.listInstResultMapping(
                page,
                limit,
              );
              global.filter = { mode: 'pagination', page, limit };
            }}
            onFilter={(type, filter, page, limit) => {
              instResultMappingStore.instResultMappingService.filter({
                input: { type, filter, page, limit },
              });
              global.filter = { mode: 'filter', type, filter, page, limit };
            }}
            isInputView={isInputView}
            setInputView={setInputView}
            onApproval={async (records) => {
              setModalConfirm({
                show: true,
                type: 'updateFields',
                data: { fields: { status: 'A' }, id: records._id },
                title: 'Are you sure?',
                body: 'Do you want to update this record?',
              });
            }}
          />
        </div>
        <ModalImportFile
          accept='.csv,.xlsx,.xls'
          {...modalImportFile}
          click={(file: any) => {
            setModalImportFile({ show: false });
            handleFileUpload(file);
          }}
          close={() => {
            setModalImportFile({ show: false });
          }}
        />
        <ModalConfirm
          {...modalConfirm}
          click={(action) => {
            setModalConfirm({ show: false });
            if (action === 'delete') {
              instResultMappingStore.instResultMappingService
                .deleteInstResultMapping({
                  input: {
                    id: modalConfirm.id,
                  },
                })
                .then((res) => {
                  if (res.removeInstrumentResultMapping.success) {
                    Toast.success({
                      message: `😊 ${res.removeInstrumentResultMapping.message}`,
                    });
                    if (global?.filter?.mode == 'pagination')
                      instResultMappingStore.instResultMappingService.listInstResultMapping(
                        global?.filter?.page,
                        global?.filter?.limit,
                      );
                    else if (global?.filter?.mode == 'filter')
                      instResultMappingStore.instResultMappingService.filter({
                        input: {
                          type: global?.filter?.type,
                          filter: global?.filter?.filter,
                          page: global?.filter?.page,
                          limit: global?.filter?.limit,
                        },
                      });
                    else
                      instResultMappingStore.instResultMappingService.listInstResultMapping();
                  }
                });
            } else if (action == 'updateFields') {
              instResultMappingStore.instResultMappingService
                .update({
                  input: {
                    ...modalConfirm.data.fields,
                    _id: modalConfirm.data.id,
                  },
                })
                .then((res) => {
                  if (res.updateInstrumentResultMapping.success) {
                    Toast.success({
                      message: ` ${res.updateInstrumentResultMapping.message}`,
                    });
                    if (global?.filter?.mode == 'pagination')
                      instResultMappingStore.instResultMappingService.listInstResultMapping(
                        global?.filter?.page,
                        global?.filter?.limit,
                      );
                    else if (global?.filter?.mode == 'filter')
                      instResultMappingStore.instResultMappingService.filter({
                        input: {
                          type: global?.filter?.type,
                          filter: global?.filter?.filter,
                          page: global?.filter?.page,
                          limit: global?.filter?.limit,
                        },
                      });
                    else
                      instResultMappingStore.instResultMappingService.listInstResultMapping();
                  }
                });
            }
          }}
          close={() => setModalConfirm({ show: false })}
        />
      </>
    );
  }),
);
export default InstResultMapping;
