import { makeObservable, action, observable, computed } from 'mobx';
import { InstrumentMaster } from '../models';
import { InstrumentMasterService } from '../services';

export class InstrumentMasterStore {
  instrumentMaster!: InstrumentMaster;
  instrumentMasterList: InstrumentMaster[];
  instrumentMasterListCopy: InstrumentMaster[];
  instrumentMasterCount: number;

  constructor() {
    this.instrumentMasterList = [];
    this.instrumentMasterListCopy = [];
    this.instrumentMasterCount = 0;
    this.instrumentMaster = new InstrumentMaster({});

    makeObservable<InstrumentMasterStore, any>(this, {
      instrumentMaster: observable,
      instrumentMasterList: observable,
      instrumentMasterListCopy: observable,
      instrumentMasterCount: observable,

      instrumentMasterService: computed,
      updateInstrumentMaster: action,
    });
  }

  reset() {
    this.instrumentMaster = new InstrumentMaster({});
    this.instrumentMasterList = [];
    this.instrumentMasterCount = 0;
  }

  get instrumentMasterService() {
    return new InstrumentMasterService();
  }

  updateInstrumentMaster = (value: InstrumentMaster) => {
    this.instrumentMaster = value;
  };
}
