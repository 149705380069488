import React, { useRef } from 'react';
import { PdfHeading, PdfHeader, PdfImage } from '@components';
import { Image, View } from '@react-pdf/renderer';

interface PdfPageBrandingHeaderProps {
  data: any;
}

export const PdfPageBrandingHeader = ({ data }: PdfPageBrandingHeaderProps) => {
  const headerTitleCSS = useRef<any>({});
  const headerMainBoxCSS = useRef<any>({});
  const imageCSS = useRef<any>({});
  if (data.pageBranding?.header?.titleCSS) {
    try {
      headerTitleCSS.current = eval(
        '({' + data.pageBranding?.header?.titleCSS + '})',
      );
    } catch (e) {
      headerTitleCSS.current = {};
    }
  }
  if (data.pageBranding?.header?.mainBoxCSS) {
    try {
      headerMainBoxCSS.current = eval(
        '({' + data.pageBranding?.header?.mainBoxCSS + '})',
      );
    } catch (e) {
      headerMainBoxCSS.current = {};
    }
  }
  if (data.pageBranding?.header?.logoCSS) {
    try {
      imageCSS.current = eval('({' + data.pageBranding?.header?.logoCSS + '})');
    } catch (e) {
      imageCSS.current = {};
    }
  }

  return (
    <>
      <PdfHeader
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          ...headerMainBoxCSS.current,
        }}
        fixed
      >
        {data?.pageBranding?.header?.backgroundImage && (
          <>
            <View
              style={{
                position: 'absolute',
                bottom: 0,
                left: 0,
                right: 0,
                top: 0,
              }}
              fixed={true}
            >
              <Image
                object-fit='fill'
                style={{
                  position: 'absolute',
                  bottom: 0,
                  left: 0,
                  right: 0,
                  top: 0,
                  objectFit: 'fill',
                }}
                source={{
                  uri:
                    typeof data?.pageBranding?.header?.backgroundImage ==
                    'object'
                      ? data.pageBranding?.header?.backgroundImageBase64
                      : data.pageBranding?.header?.backgroundImage,
                  method: 'GET',
                  headers: { 'Cache-Control': 'no-cache' },
                  body: '',
                }}
              />
            </View>
          </>
        )}
        {data.pageBranding?.header?.logo && (
          <PdfImage
            src={data.pageBranding.header?.logo}
            style={{
              width: 50,
              height: 50,
              borderRadius: 25,
              marginHorizontal: 10,
              ...imageCSS.current,
            }}
          />
        )}
        <PdfHeading style={headerTitleCSS.current}>
          {data.pageBranding?.header?.title || ''}
        </PdfHeading>
      </PdfHeader>
    </>
  );
};
